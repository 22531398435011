import type React from 'react';
import { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
  fallBack?: React.ReactNode;
};

const ClientOnly = ({ children, fallBack }: Props) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return <>{!hasMounted ? fallBack || null : children}</>;
};

export default ClientOnly;
