'use client';
import { useEffect, useLayoutEffect } from 'react';

/**
 * Function to generate the src for the script tag
 * Refs: https://developers.google.com/recaptcha/docs/loading
 */
const getRecaptchaScriptSrc = ({ reCaptchaKey, language, useRecaptchaNet = false } = {}) => {
  const hostName = useRecaptchaNet ? 'recaptcha.net' : 'google.com';
  const script = 'api.js';
  let src = `https://www.${hostName}/recaptcha/${script}?`;
  if (reCaptchaKey) src += `render=${reCaptchaKey}`;
  if (language) src += `&hl=${language}`;
  return src;
};
// https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export { getRecaptchaScriptSrc, useIsomorphicLayoutEffect };
