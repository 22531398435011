import { GREY_200, NEUTRAL_WHITE, PURPLE_1000 } from '@mnd-frontend/ui';
import styled, { css } from 'styled-components';
import ClientOnly from '../ClientOnly';

const Wrapper = styled.div<{ $prReport: boolean }>`
  position: relative;

  label {
    font-weight: normal;
    font-size: var(--font-size-x-small);
    line-height: 1.2;
    color: var(--text-color);
    position: absolute;
    top: 0.5rem;
    left: var(--form-field-padding-x);
    margin: 0;
    pointer-events: none;
    opacity: 0;
    transform: translateY(0.5rem);
    transition: all 0.25s 0s var(--easing);

    ${({ $prReport }) =>
      $prReport &&
      css`
        color: ${NEUTRAL_WHITE};
        opacity: 1;
        top: 0;
        transform: translateY(50%);
        font-size: 0.9375rem;
        line-height: 1.5;
      `}
  }

  input {
    height: 56px;
    appearance: none;
    background-color: var(--form-field-bg);
    border: 1px solid var(--form-field-border-color);
    border-radius: 0;
    color: var(--text-color);
    display: inline-block;
    font-family: var(--body-font-family);
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 0;
    max-width: 100%;
    outline: 0;
    padding: var(--form-field-padding-y) var(--form-field-padding-x);
    text-align: left;
    text-decoration: none;
    text-transform: none;
    width: 100%;

    transition: all 0.25s 0s var(--easing);

    &:focus {
      border-color: var(--form-field-focus-border-color);
      background-color: var(--form-field-focus-bg);
    }

    &:not(:placeholder-shown) {
      padding-bottom: 0.5rem;
      padding-top: calc(var(--form-field-padding-y) * 2 - 0.5rem);

      + label {
        opacity: 0.5;
        transform: translateY(0);
      }
    }

    ${({ $prReport }) =>
      $prReport &&
      css`
        height: 44px;
        background-color: transparent;
        border-radius: 0.5rem;
        color: ${NEUTRAL_WHITE};
        &:focus {
          background-color: transparent;
        }
        &:not(:placeholder-shown) {
          padding-bottom: 0;
          padding-top: 0;
          + label {
            background-color: ${PURPLE_1000};
            color: ${GREY_200};
            opacity: 1;
            padding: 0 0.5rem;
            font-size: 0.55rem;
            transform: translateY(-6px);
          }
        }
      `}
  }
`;

const InputFallback = styled.div`
  height: 56px;
  width: 100%;
  background-color: var(--form-field-bg);
`;

const ErrorText = styled.div`
  color: var(--color-red);
  font-size: var(--font-size-x-small);
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  prReport?: boolean;
  errors?: string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input = ({ label, prReport = false, onChange, ...rest }: Props) => (
  <Wrapper $prReport={prReport}>
    <input
      {...rest}
      id={label}
      placeholder={prReport ? '' : label}
      onChange={onChange}
      autoComplete="off"
    />
    <label htmlFor={label}>{label}</label>
  </Wrapper>
);

const ClientOnlyInput = ({ errors, label, prReport = false, onChange, ...rest }: Props) => (
  <ClientOnly fallBack={<InputFallback />}>
    <Input label={label} prReport={prReport} onChange={onChange} {...rest} />
    {errors?.map((error, index) => (
      <ErrorText key={index}>{error}</ErrorText>
    ))}
  </ClientOnly>
);

export default Input;
export { ClientOnlyInput };
