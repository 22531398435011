'use client';
import { useEffect } from 'react';
import { useReCaptcha } from './useReCaptcha.js';

/** React Component to generate ReCaptcha token
 * @example
 * <ReCaptcha action='form_submit' onValidate={handleToken} />
 */
const ReCaptcha = ({ action, onValidate, validate = true, reCaptchaKey }) => {
  const { loaded, executeRecaptcha } = useReCaptcha(reCaptchaKey);
  useEffect(() => {
    if (!validate || !loaded) return;
    if (typeof onValidate !== 'function') return;
    const handleExecuteRecaptcha = async () => {
      const token = await executeRecaptcha(action);
      onValidate(token);
    };
    handleExecuteRecaptcha();
  }, [action, onValidate, validate, loaded, executeRecaptcha]);
  return null;
};

export { ReCaptcha };
