import { PHASE_PRODUCTION_BUILD } from 'next/constants';

export const PASSWORD_MIN_LENGTH = 8;

export const SignupPageTypes = {
  MONITOR_REPORTS: 'monitor-reports',
  PRODUCT_TOUR: 'product-tour',
} as const;

export const CACHE_TTL = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD ? 240 : 120;
