'use client';

import { Honeybadger } from '@honeybadger-io/react';
import { Text, useToasts } from '@mnd-frontend/ui';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  countryKeys,
  countryTranslationKeys,
  prioritizedCountryKeys,
} from '../../assets/data/countries';
import { CACHE_TTL, PASSWORD_MIN_LENGTH, type SignupPageTypes } from '../../constants';
import useFormPasswordValidation from '../../hooks/useFormPasswordValidation';
import { ReCaptchaProvider, useReCaptcha } from '../../lib/next-recaptcha-v3';
import { onFormInvalid, selectContentClickHandler, track } from '../../lib/tracker';
import { routes } from '../../routes';
import { getCookieByName } from '../../utils/cookies';
import { formatUrl } from '../../utils/formatUrl';
import { formatErrorMessage } from '../../utils/handleErrors';
import { mapLangToLocale } from '../../utils/locale';
import logger from '../../utils/logger';
import { getTrackingEventByType } from '../../utils/tracking';
import Button from '../Button';
import Checkbox from '../Checkbox';
import FormTitleBanner from '../FormTitleBanner';
import { ClientOnlyInput } from '../Input';
import Select from '../Select';

const Form = styled.form<{ $hasTitle: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  background: var(--color-white);
  padding: var(--card-padding) var(--card-padding-small);
  ${({ $hasTitle }) =>
    $hasTitle &&
    css`
      position: relative;
    `}

  @media (min-width: 768px) {
    padding: var(--card-padding);
  }
  a {
    color: var(--link-color);
  }
`;

const FormButton = styled(Button)`
  width: 100%;
  align-self: flex-start;
  font-size: var(--font-size-large);
  margin-top: var(--spacing-medium);
  padding: 1.5rem 1.25rem;

  @media (min-width: 960px) {
    width: auto;
  }
`;

const FormPolicyText = styled.span`
  font-size: 0.75rem;
  color: var(--color-gray);
`;

const InlineLink = styled.a`
  color: var(--link-color);
  text-decoration: underline;
`;

type FormData = {
  title: string | null;
  description: string | null;
  submitButton: string | null;
  acceptTerms: string | null;
  terms: string | null;
  newsletterOptIn: string | null;
  bgColor: string | null;
};

const SignupForm = ({
  signupType,
  formData,
}: {
  signupType?: (typeof SignupPageTypes)[keyof typeof SignupPageTypes];
  formData?: FormData | null;
}) => {
  const { executeRecaptcha } = useReCaptcha();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { showFailureToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const formTitle = formData?.title;
  const formId = `mnd-signup-form-${signupType ?? 'base'}`;

  useFormPasswordValidation();

  const handleRecpatchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      logger.error('ReCAPTCHA not available');
      return;
    }
    const token = await executeRecaptcha('TrialSignup');
    return token;
  }, [executeRecaptcha]);

  const submitButtonText = formData?.submitButton
    ? formData.submitButton
    : signupType === 'monitor-reports'
      ? t('form.formCta')
      : t('productTour.signup.submitCta');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const { site_code, accept_terms, newsletter_opt_in, ...rest } = Object.fromEntries(
      formData.entries(),
    ) as Record<string, string>;
    const hubspotCookie = getCookieByName('hubspotutk');
    const token = await handleRecpatchaVerify();

    const trackError = (error_code: string, error_message: string, form_field: string) => {
      track({
        event: 'error_form_submit',
        traits: {
          event_info: {
            form_id: formId,
            error_code,
            error_message,
            form_field,
            form_name: formTitle ?? '',
            navigation_selection: submitButtonText,
            user_interaction: 'cta_click',
          },
        },
      });
    };

    if (!token) {
      showFailureToast('Recaptcha validation failed');
      setLoading(false);
      trackError('captcha', 'Failed to verify recaptcha token', 'captcha');
      return;
    }

    try {
      const verifyRecaptchaResponse = await fetch('/api/verify-captcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
        next: {
          revalidate: CACHE_TTL,
        },
      });
      if (!verifyRecaptchaResponse.ok) {
        showFailureToast('Failed to verify recaptcha token');
        setLoading(false);
        trackError('captcha', 'Failed to verify recaptcha token', 'captcha');
        return;
      }
    } catch (e) {
      showFailureToast('Failed to verify recaptcha token');
      setLoading(false);
      trackError('captcha', 'Failed to verify recaptcha token', 'captcha');
      return;
    }

    const normalizedData = {
      type: signupType,
      site_code: site_code.toLowerCase(),
      locale: mapLangToLocale(lang),
      accept_terms: accept_terms === 'on',
      newsletter_opt_in: newsletter_opt_in === 'on',
      hubspot_session: hubspotCookie,
      ...rest,
    };

    try {
      const resp = await fetch('/api/v1/marketing/trial_signups', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(normalizedData),
      });
      if (resp.ok) {
        try {
          const mktParam = new URLSearchParams(window.location.search).get('mkt');

          track({
            event: getTrackingEventByType(signupType),
            traits: {
              market: mktParam ?? '',
            },
          });
          track({
            event: 'lead_generation',
            traits: {
              event_info: {
                form_id: formId,
                lead_source: '',
                navigation_selection: submitButtonText,
                form_name: formTitle || formId,
                user_interaction: 'lead_generation',
              },
            },
          });
        } catch (e) {
          console.error(e);
        }
        const respData: { redirect_url: string } = await resp.json();
        window.location.href = respData.redirect_url;
      } else {
        const respData: { error_messages: { [key: string]: string[] } } = await resp.json();
        const errMsg = formatErrorMessage(respData.error_messages) || 'Something went wrong.';
        showFailureToast(errMsg);
        if (Object.keys(respData.error_messages).length) {
          Object.entries(respData.error_messages).forEach(([key, messages]) => {
            messages.forEach(msg => {
              trackError(msg, msg, key);
            });
          });
        } else {
          trackError('unknown', errMsg, 'unknown');
        }
      }
    } catch (e) {
      showFailureToast('Something went wrong.');
      trackError('unknown', 'Something went wrong.', 'unknown');
      Honeybadger.notify(e as Error, {
        message: `Trial signup failed - ${signupType}`,
      });
    }
    setLoading(false);
  };

  const countryOptions = useMemo(() => {
    return [
      ...(prioritizedCountryKeys
        .map(key => {
          const translatedValue = t(countryTranslationKeys[key]);
          if (!translatedValue.startsWith('locale')) {
            return [key, translatedValue];
          }
          return null;
        })
        .filter(entry => entry) as [string, string][]),
      ['---', '---'],
      ...(countryKeys
        .map(key => {
          const translatedValue = t(countryTranslationKeys[key]);
          if (!translatedValue.startsWith('locale')) {
            return [key, translatedValue];
          }
          return null;
        })
        .filter(entry => entry) as [string, string][]),
    ];
  }, [t]);

  const TermsText = () => {
    if (formData && formData.terms) {
      return (
        <span>
          <a
            href={formatUrl(routes.termsConditions(lang))}
            onClick={selectContentClickHandler('terms', 'terms')}
            dangerouslySetInnerHTML={{ __html: formData.acceptTerms || '' }}
          />
        </span>
      );
    } else {
      return (
        <span>
          <Trans
            i18nKey="form.termsAndConditions"
            components={{
              a: (
                <InlineLink
                  href={routes.termsConditions(lang)}
                  onClick={selectContentClickHandler('terms_and_conditions', 'terms')}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </span>
      );
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      $hasTitle={!!formTitle}
      id={formId}
      onInvalid={onFormInvalid(formId, formTitle || formId, submitButtonText)}
    >
      {formTitle && <FormTitleBanner title={formTitle} background={formData.bgColor} />}
      <ClientOnlyInput name="name" type="text" label={t('form.name')} required />
      <ClientOnlyInput name="email" type="email" label={t('form.email')} required />
      <ClientOnlyInput
        name="password"
        type="password"
        label={t('form.password')}
        required
        minLength={PASSWORD_MIN_LENGTH}
        data-password-validation
      />
      <ClientOnlyInput name="phone" type="tel" label={t('form.phone')} required />
      <ClientOnlyInput name="company_name" type="text" label={t('form.companyName')} required />
      <Select
        name="site_code"
        label={t('form.chooseCountry')}
        options={countryOptions}
        defaultValue=""
        required
      />
      <Checkbox
        name="newsletter_opt_in"
        label={formData?.newsletterOptIn ? formData.newsletterOptIn : t('form.marketingConsent')}
      />
      <Checkbox name="accept_terms" label={<TermsText />} required />
      <FormButton variant="primary" type="submit" disabled={loading}>
        {submitButtonText}
      </FormButton>
      {signupType === 'product-tour' && (
        <Text as="span" variant="labelSmall">
          {t('productTour.signup.formKicker')}
        </Text>
      )}
      <FormPolicyText>
        <Trans
          i18nKey="form.personalDataUsage"
          components={{
            link1: (
              <InlineLink
                href={routes.privacyPolicy(lang)}
                onClick={selectContentClickHandler('privacy_policy', 'terms')}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            link2: (
              <InlineLink
                href={routes.termsUse(lang)}
                onClick={selectContentClickHandler('terms_of_use', 'terms')}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </FormPolicyText>
    </Form>
  );
};

export const SignupFormWrapper = ({
  signupType,
  formData,
}: {
  signupType?: (typeof SignupPageTypes)[keyof typeof SignupPageTypes];
  formData?: FormData | null;
}) => {
  return (
    <ReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
      async={false}
      defer={false}
      className="optanon-category-C0001"
    >
      <SignupForm signupType={signupType} formData={formData} />
    </ReCaptchaProvider>
  );
};
