import { NEUTRAL_WHITE, PURPLE_600 } from '@mnd-frontend/ui';
import styled from 'styled-components';

const Wrapper = styled.div<{ $prReport: boolean }>`
  input {
    height: 1.2rem;
    margin: 0 0.35rem 0 0;
    align-self: flex-start;
    background-color: transparent;
    accent-color: ${({ $prReport }) => ($prReport ? PURPLE_600 : 'var(--color-primary, auto)')};
  }

  label {
    display: flex;
    font-size: var(--font-size-small);
    color: ${({ $prReport }) => ($prReport ? NEUTRAL_WHITE : 'var(--text-color)')};
    letter-spacing: var(--body-letter-spacing, 0);
    line-height: var(--body-line-height, 1.6);
  }
`;

const Checkbox = ({
  label,
  name,
  required,
  prReport = false,
}: {
  label: string | React.ReactNode;
  name: string;
  required?: boolean;
  prReport?: boolean;
}) => {
  return (
    <Wrapper $prReport={prReport}>
      <label>
        <input type="checkbox" name={name} required={required} />
        {label}
      </label>
    </Wrapper>
  );
};

export default Checkbox;
