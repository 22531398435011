'use client';
import React from 'react';
import { useReCaptcha } from './useReCaptcha.js';

/** React HOC to generate ReCaptcha token
 * @example
 * withReCaptcha(MyComponent)
 */
function withReCaptcha(WrappedComponent) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithReCaptcha = props => {
    const reCaptchaProps = useReCaptcha();
    // Pass current token and function to generate it to the component
    return React.createElement(WrappedComponent, { ...reCaptchaProps, ...props });
  };
  ComponentWithReCaptcha.displayName = `withReCaptcha(${displayName})`;
  return ComponentWithReCaptcha;
}

export { withReCaptcha };
