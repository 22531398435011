import pino from 'pino';

const logger = pino({
  level: process.env.NODE_ENV === 'production' ? 'info' : 'debug',
  transport:
    process.env.NODE_ENV !== 'production'
      ? {
          target: 'pino-pretty', // Use pino-pretty for development
          options: {
            colorize: true,
            levelFirst: true,
            translateTime: 'yyyy-mm-dd HH:MM:ss',
          },
        }
      : undefined, // Use default Pino in production
});

export const logError = (
  errorType: 'fetch-url-error' | 'http-error' | 'graphql-query-error' | 'redis-error',
  details: any = {},
) => {
  switch (errorType) {
    case 'fetch-url-error':
      logger.error({ details }, 'Fetch URL error: No URL provided');
      break;
    case 'http-error':
      logger.error({ details }, 'HTTP error: Request failed');
      break;
    case 'graphql-query-error':
      logger.error({ details }, 'GraphQL query error: Issues with the query');
      break;
    default:
      logger.error({ details }, 'Unknown error');
  }
};

export default logger;
